import React, {useEffect, useRef, useState} from "react";
import {math} from "polished";
import model from "@images/index-hero-michelle-bg.jpg";
import modelMobile from "@images/index-hero-michelle-bg-mobile.jpg";
import slogan from "@images/index-hero-michelle-slogan.png";
import slogan2 from "@images/index-hero-michelle-slogan2.png";
import styled, {keyframes, useTheme} from "styled-components";


const Root = styled.div`
  position: relative;
  overflow: hidden;

  ${({theme}) => theme.breakpoints.xsMax} {
    max-height: 95vh; // Fallback
    max-height: calc(100vh - ${({theme}) => theme.headerHeightXsMax});
  } 

  &:before {
    content: '';
    display: block;
    padding-top: ${(753 / 1920) * 100}%;

    ${({theme}) => theme.breakpoints.xsMax} { padding-top: 177.77778%; }
  }
`;

const Model = styled.img`
  position: absolute;
  width: 110%;
  top: -30px !important;
  max-width: initial; // Override global style
  bottom: 0% !important;
  right: -5% !important;
  opacity: ${({$show}) => $show ? 1 : 0};
  ${({theme}) => theme.breakpoints.xsMax} {
    top: 0 !important;
    width: 99%;
  }
`;

const Slogan1 = styled.img`
  position: absolute;
  width: 26%;
  top: 23% !important;
  left: 20% !important;

  ${({theme}) => theme.breakpoints.xsMax} {
    display: none !important;
  }
`;

const Slogan2 = styled.img`
  position: absolute;
  width: 36%;
  top: 55% !important;
  left: 15% !important;

  ${({theme}) => theme.breakpoints.xsMax} {
    display: none !important;
  }
`;
export function useWindowDimension() {
    const [dimension, setDimension] = useState([
        typeof window !== "undefined" ? window.innerWidth : 0,
        typeof window !== "undefined" ? window.innerHeight: 0,
    ]);
    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            console.log('***** debounced resize'); // See the cool difference in console
            setDimension([ typeof window !== "undefined" ? window.innerWidth : 0,  typeof window !== "undefined" ? window.innerHeight : 0]);
        }, 100); // 100ms
        (typeof window !== "undefined") && (window.addEventListener('resize', debouncedResizeHandler));
        return () =>  (typeof window !== "undefined") && window.removeEventListener('resize', debouncedResizeHandler);
    }, []); // Note this empty array. this effect should run only on mount and unmount
    return dimension;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}


export default function HeroMichelle () {
    const [show, setShow] = useState(false);
    const rootRef = useRef();
    const {breakpointWidths} = useTheme();
    const [width, height] = useWindowDimension();
    const sm = typeof window !== "undefined" ? window.innerWidth >= breakpointWidths.sm : true;
    const [size, setSize] = useState(false);
    useEffect(() => { setShow(true); }, []);
    useEffect(() => {
        let check = width >= breakpointWidths.sm;
        if(check){
            setSize(model);
        }else{
            setSize(modelMobile);
        }
    }, [width]);
    useEffect(() => {
        import("parallax-js").then(({default: Parallax}) => {
            new Parallax(rootRef.current);
        });
    }, []);

    return (
        <Root ref={rootRef}>
            <div></div>
            <Model src={size} $show={show} data-depth={sm ? "0.2" : "0.4"} alt="Belotero model on Hero" />
            <Slogan1 src={slogan} $show={show} data-depth={sm ? "0.23" : "0.46"} alt="BEAUTIFUL" />
            <Slogan2 src={slogan2} $show={show} data-depth={sm ? "0.25" : "0.5"} alt="just got EASIER" />
        </Root>
    );
}
